import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import LatestPosts from "../components/blogposts-latest";
import Seo from "../components/seo";
import Date from "../components/date";
import Commento from "../components/commento"
import EmailSignup from '../components/email-signup'

import "../style/blog-singlepage.less";
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import SectionTitle from "../components/sectiontitle";
deckDeckGoHighlightElement();

export default function BlogShow({ data, location }) {
  return (
    <Layout>
      <Seo
        lang="en"
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        image={data.markdownRemark.frontmatter.image.publicURL}
      />
      <div className="container">
        <article className="blog-post">
          {data.markdownRemark.frontmatter.banner != null && (
            <div className="banner">
              <GatsbyImage
                image={data.markdownRemark.frontmatter.banner.childImageSharp.gatsbyImageData} />
            </div>
          )}
          <div className="section-title">
            <SectionTitle title={data.markdownRemark.frontmatter.title} />
          </div>
          <div className="head text-primary">
            {/* <h1>{data.markdownRemark.frontmatter.title}</h1> */}
            <p className="post-date">
            {data.markdownRemark.fields.readingTime.text} <strong>|</strong> <Date data={data.markdownRemark.frontmatter.date} />
            </p>
          </div>
          <div className="content row flex">
            <div
              className="col s12 m11 l10"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html
              }}
            ></div>
          </div>
        </article>
        <div className="row">
          <p className="small-text section-title" style={{ maxWidth: '420px', margin: 'auto' }}>
            {data.markdownRemark.frontmatter.unsplash &&
              <>
                {data.markdownRemark.frontmatter.unsplash === 'self'
                ? <>
                  Header Photo / Banner by: <a href="https://sullyshoots.com/" rel="noreferrer nofollow" target="_blank">me!</a>
                </>
                : <>
                  Header Photo / Banner by: <a href={`https://unsplash.com/${data.markdownRemark.frontmatter.unsplash}`} target="_blank" rel="nofollow noopener noreferrer">{data.markdownRemark.frontmatter.unsplash}</a>
                </>
                }
              </>
            }
          </p>
        </div>
        <section className="row">
          <EmailSignup />
        </section>
        <section className="row center-text" style={{ maxWidth: 600 }}>
            <h5>Join the Conversation</h5>
            <Commento />
        </section>
        <LatestPosts id={data.markdownRemark.id} />
      </div>
    </Layout>
  );
}

export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    id
    fields {
      readingTime {
        text
      }
    }
    frontmatter {
      title
      date
      description
      unsplash
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      banner {
        publicURL
        childImageSharp {
          gatsbyImageData(aspectRatio: 3.2, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`;
