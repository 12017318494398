import React from 'react'

const EmailSignup = () => (
  <div className="center-text" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px 0 10px' }}>
    <form action="https://tinyletter.com/jonsully" method="post" target="_blank" noValidate>
      <h5>Straight to the Inbox!</h5>

      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
        <label htmlFor="tlemail">
          <div className="input-border">
            <input type="text" className="field-box" name="email" placeholder="Email" style={{ width: '300px' }} required />
          </div>
        </label>

        <input type="submit" value="Subscribe" name="subscribe" className="btn" />
        <input type="hidden" value="1" name="embed"/>
      </div>

      <p><strong>I post less than once/month.<br/>No spam. No data selling. Never.</strong></p>

      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input type="text" name="b_5b8f414dbf7cb4caea7dad7df_8a358dbc54" tabIndex="-1" defaultValue="" />
      </div>
    </form>
  </div>
)

export default EmailSignup
        